
:root {
  --link-color: #9797ff;
  --link-color-dark: #6262e2;
  --link-color-dark-xtra: #5050c6;
  --transparent-bg-color: rgba(0, 0, 0, 0.6);
}

@font-face {
  font-family: 'sf-pro';
  src: url(./fonts/SF-Pro-Display-Regular.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: black;
}
.paragraph {
  margin: 0;
  text-align: center;
  transform: translate(-50%, -50%);
  color: white;
  letter-spacing: 1px;
  font-family: Space Age;
  font-size: 1rem;
  width: 25vw;
  height: 25vh;
  font-family: 'sf-pro';
  min-width: 500px;
  height: 100%;
}

.title-line {
  font-size: 1.35rem;
  height: fit-content;
  position: absolute;
  top: -18vh;
}

@media (max-width: 768px) {
  .title-line {
    font-size: 1.15rem !important;
    margin-bottom: 38%;
    margin-top: -18%;
  }
}

.long {
  width: 40vw;
  position: absolute;
  top: 15vh;
  height: fit-content;
}

.about .paragraph {
  text-align: left;
  min-width: auto;
}
/* media queries, increase paragraph width to 100vw on mobile */
@media (max-width: 768px) {
  .paragraph {
    width: 100vw;
    font-size: 0.85rem;
  }
}

h2 {
  font-weight: 400;
  margin-top: -4rem;
  margin-bottom: 5rem;
  font-size: 1.5rem !important;
  text-align: left;
  font-family: 'sf-pro';
  display: contents;
}

a {
  color: var(--link-color-dark);
  pointer-events: auto;
  text-decoration: none;
  transition: 0.2s ease-out;
}

a:hover {
  text-decoration: underline;
}

@keyframes rollout {
  0% { opacity: 0; }
  33% { opacity: 0; }
  100% { opacity: 1; }
}

.explainer-wrap {
  background: var(--transparent-bg-color);
  backdrop-filter: blur(10px);
  font-family: 'sf-pro';
  padding: 2rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  height: 100%;
  border-radius: 4px;
  transform: translate(-50%, -50%);
  gap: 1rem;
  max-width: 550px;
  min-width: 500px;
  animation: rollout 2s ease-out;
  opacity: 1;
}

.explainer-wrap p {
  transform: none;
  height: fit-content;
  font-size: 1rem;
  width: 100%;
}

@media (max-width: 768px) {
  .explainer-wrap {
    transform: translate(-50%, -35%);
    min-width: 70vw;
  }
  .explainer-wrap p {
    font-size: 0.85rem;
  }
}

nav {
  font-size: 1rem;
  display: flex;
}

nav a {
  color: white;
}

nav button {
  color: white;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  width: fit-content;
  padding: 0;
  transition: 0.2s ease-out;
}

nav button:focus {
  outline: none;
}

nav button:hover {
  text-decoration: underline;
  opacity: 0.8;
}

ul {
  padding: 0;
  margin: 0;
  display: flex;
  gap: 1rem;
  transform: translate(-46vw, -46vh);
}

.light-mode button {
  color: black;
}

li {
  list-style-type: none;
}

@media (max-width: 768px) {
  ul {
    gap: 0.75rem;
    transform: translate(-44vw, -44vh);
  }
  nav button {
    font-size: 0.85rem;
  }
}

.loader {
  width: 24px;
  height: 24px;
  border: 2px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  }

  .loader-wrap {
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
  }

  .light-mode .paragraph {
    color: black;
  }